.form-group {
  margin-bottom: 20px !important;
  position: relative;

  @media screen and (max-width: $min-lg - 1) {
    margin-bottom: 10px !important;
  }

  .add-item {
    font-size: 13px;
    color: #0885C2;
    float: left;
    font-family: 'cairo-regular', sans-serif;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    img {
      margin-left: 2px;
      width: 13px;
    }
  }

  .form-label {
    color: $black-primary;
    font-size: 14px;
    letter-spacing: -0.14px;
    margin-bottom: 10px;
    line-height: 26px;
    font-family: "cairo-bold", sans-serif;

    .optional {
      color: #A0A3AE;
      font-size: 12px;
      font-family: 'cairo-regular', sans-serif;
      margin-right: 4px;
    }

    @media screen and (max-width: $min-lg - 1) {
      font-size: 13px;
      letter-spacing: -0.13px;
      margin-bottom: 9px;
    }
  }

  .input-append {
    position: absolute;
    bottom: 1px;
    left: 15px;
    color: $dark-grey;
    font-size: 14px;
    letter-spacing: -0.14px;
    line-height: 38px;
    font-family: "cairo-regular", sans-serif;
    background: $lighter-grey-2;
    padding-right: 14px;
    height: 40px;
  }
}

// form control
input.form-control,
select.form-control {
  border: 1px solid $grey-5;
  color: $dark-grey;
  background: $grey-6;
  border-radius: 5px;
  font-family: 'cairo-regular', sans-serif;
  font-size: 14px;
  letter-spacing: -0.14px;
  padding: 8px 15px;
  height: 42px;
  line-height: 42px;

  &[readonly] {
    border-color: $grey-6;
    background-color: $grey-7;
    cursor: auto;
  }

  @media screen and (max-width: $min-lg - 1) {
    font-size: 13px;
    letter-spacing: -0.13px;
  }

  &:focus {
    border-color: $blue-primary;
    color: $grey-2;
    background: $grey-6;
    outline: 0 !important;
    box-shadow: none !important;
  }

  &:focus[readonly] {
    border-color: $grey-6;
  }

  &.invalid-field {
    border: 1px solid #D9727F;
    border-radius: 5px;
  }
}



// custom select
.custom-option {
  position: relative;
  display: inline-block;
  width: 100%;

  &::before,
  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
  }

  &::after {
    background: url("../../images/svg/Polygon.svg");
    background-repeat: no-repeat;
    background-position: center center;
    content: "";
    left: 0px;
    top: 0px;
    bottom: 0px;
    cursor: pointer;
    width: 50px;
  }

  select {
    line-height: 24px;
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;

    &::-ms-expand {
      display: none;
    }

    option {
      color: rgba($color: $black, $alpha: 0.6);
      font-size: .9rem;
      font-weight: 400;
      cursor: pointer;

      &:disabled {
        background: transparent;
        color: rgba($color: $black, $alpha: 0.2);
        font-size: 0.8rem;
      }
    }
  }
}

.was-validated select.form-control:valid {
  border-color: $light-grey-2 !important;
  padding: 8px 15px !important;
}

.droppoints {
  text-align: right;
  padding: 0;

  .radio-div {
    position: relative;

    label {
      margin-right: 22px;
    }
  }

  .dropItem {
    display: inline-block;
    margin-left: 40px;
    font-size: 14px;
    font-family: 'cairo-semibold', sans-serif;
  }
}

input[type='radio'] {
  -webkit-appearance: none;
  position: absolute;
  right: 0;
  top: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  outline: none;
  border: 1px solid $grey-4;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  margin: 3px auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: $blue-primary;

}

input[type="radio"]:checked {
  border-color: $grey-4;
}

.custom-radio {
  .custom-control-label {
    display: inline-block;
    padding-right: 26px;
    font-family: 'cairo-bold', sans-serif;
    font-size: 14px;
    letter-spacing: -0.14px;
    color: $dark-grey;
    cursor: pointer;

    &::before {
      right: -4px;
      left: auto;
      top: 2px;
      width: 16px;
      height: 16px;
      border: 1.5px solid $box-border;
    }

    &::after {
      right: 0px;
      left: auto;
      top: 6px;
      width: 8px;
      height: 8px;
      background: transparent;
      border-radius: 10px;
    }
  }

  .custom-control-input:checked~.custom-control-label::before {
    background: transparent !important;
    border: 1.5px solid $box-border !important;
  }

  .custom-control-input:not(:disabled):active~.custom-control-label::before {
    background: transparent !important;
    border: 1.5px solid $box-border;
  }

  .custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
  }

  .custom-control-input:checked~.custom-control-label::after {
    background: $light-blue !important;
  }
}

.was-validated .custom-control-input:valid~.custom-control-label {
  color: $dark-grey !important;
}

.was-validated .custom-control-input:valid~.custom-control-label::before {
  border-color: $box-border !important;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
  box-shadow: none !important;
}

.was-validated .custom-control-input:invalid~.custom-control-label {
  color: $dark-grey !important;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before {
  border-color: $error !important;
}

.radio-inline {
  display: flex;

  .custom-radio {
    margin-left: 40px;

    &:last-child {
      margin-left: 0px;
    }

    .custom-control-label {
      padding-right: 28px;
      font-family: 'cairo-semibold', sans-serif;

      &::before {
        right: 0px;
        left: auto;
        top: 2px;
      }

      &::after {
        right: 4px;
        left: auto;
        top: 6px;
      }
    }
  }
}

.custom-checkbox {
  .custom-control-label {
    margin-right: 30px;
    font-family: 'cairo-semibold', sans-serif;
    color: $dark-grey;
    font-size: 14px;
    letter-spacing: -.14px;

    @media screen and (max-width: $min-lg - 1) {
      font-size: 13px;
      letter-spacing: -.13px;
    }

    &:focus {
      outline: none;
    }

    &::before {
      right: -30px;
      left: auto;
      top: 2px;
      background: transparent;
      border: 1.5px solid $box-border !important;
      width: 18px;
      height: 18px;
      border-radius: 3px;
    }

    &::after {
      background-image: none !important;
      content: "";
      font-family: "Font Awesome 5 Solid", sans-serif;
      color: $light-blue;
      font-size: 10px;
      top: 4px;
      left: auto;
      right: -26px;
    }
  }

  .custom-control-input:checked~.custom-control-label::before {
    background: transparent !important;
    border: 1.5px solid $box-border !important;
  }

  .custom-control-input:not(:disabled):active~.custom-control-label::before {
    background: transparent !important;
    border: 1.5px solid $box-border !important;
  }

  .custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
  }

  .custom-control-input:checked~.custom-control-label::after {
    content: "\f00c";
  }
}

.custom-number {
  position: relative;

  .sub,
  .add {
    position: absolute;
    background: transparent;
    border: 0px;
    padding: 0px;
    width: 18px;
    height: 18px;
    display: flex;

    &:focus {
      outline: none;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .sub {
    top: 12px;
    right: 16px;
  }

  .add {
    top: 12px;
    left: 16px;
  }

  input {
    text-align: center;
  }
}

input[type=number]::-webkit-inner-spin-button {
  opacity: 0;
  display: none;
}

.gj-datepicker {
  input#datepicker {
    border-radius: 10px;
    cursor: pointer;

    &:focus {
      z-index: 0;
    }
  }

  .input-group-append {
    width: 22px;
    position: absolute;
    top: 0px;
    left: 16px;
    bottom: 0px;
    background: url("../../images/svg/calendar-icon.svg");
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: center center;

    button {
      display: none;
    }
  }
}

div[role=calendar] {
  padding: 0px;
  min-width: 266px !important;
  border-color: $light-grey-2;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba($black, 0.08);

  div[role=navigator] {
    margin: 10px 0px;

    i {
      transform: rotate(180deg);
    }
  }

  table {
    width: 100%;

    thead {
      tr {
        th {
          padding: 0 !important;
          border: 0px !important;
          min-width: auto !important;
          background: $light-grey !important;
          color: $primary !important;
          font-size: 14px;
          letter-spacing: -0.14px;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 0 !important;
          min-width: auto !important;
          color: $dark-grey !important;
          font-family: "cairo-semibold", sans-serif;
          font-size: 14px;
          letter-spacing: -0.14px;
        }
      }
    }
  }
}

.alert-box {
  display: flex;
  align-items: center;
  border: 1px solid $error;
  background: rgba($error, .1);
  border-radius: 10px;
  padding: 12px 15px;
  margin-bottom: 30px;

  @media screen and (max-width: $min-lg - 1) {
    padding: 14px 12px;
  }

  .close-alert {
    width: 18px;
    height: 18px;
    cursor: pointer;

    @media screen and (max-width: $min-lg - 1) {
      width: 20px;
      height: 20px;
    }
  }

  .alert-msg {
    color: $error;
    font-size: 14px;
    letter-spacing: -0.14px;
    margin-bottom: 0px;
    margin-right: 15px;
    font-family: 'cairo-regular', sans-serif;

    @media screen and (max-width: $min-lg - 1) {
      font-size: 13px;
      letter-spacing: -0.13px;
      margin-right: 10px;
    }
  }
}

input[type="text"]::-webkit-input-placeholder {
  color: $grey-4;
}

input[type="text"]::-moz-placeholder {
  color: $grey-4;
  opacity: 1;
}

input[type="text"]:-ms-input-placeholder {
  color: $grey-4;
}

input[type="text"]:-moz-placeholder {
  color: $grey-4;
  opacity: 1;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: $red-dark !important;
  padding: .375rem 0.75rem !important;
  background-repeat: no-repeat !important;
  background-size: calc(2.25rem / 2) calc(2.25rem / 2) !important;
  background-image: none !important;
}

.invalid-field {
  .ng-select {
    border: 1px solid #D9727F;
    border-radius: 5px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  input:not(.mat-input-element),
  .single-select,
  .mat-form-field-flex {
    border: 1px solid #D9727F;
    border-radius: 5px;
  }

  .form-control-feedback {
    display: block;
  }



  .static-code {
    margin-left: 18px !important;
  }
}

.form-control-feedback {
  position: absolute;
  width: auto;
  bottom: 13px;
  left: 14px;
  margin-top: 0;
  display: none;
}

.static-code {
  position: absolute;
  margin: 0 auto;
  top: 47px;
  bottom: 0;
  left: 15px;
  font-size: 14px;
  color: #111934;
  font-family: 'cairo-regular', sans-serif;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: $green-dark !important;
  padding: .375rem 0.75rem !important;
  background-repeat: no-repeat !important;
  background-size: calc(2.25rem / 2) calc(2.25rem / 2) !important;
  background-image: none !important;
}

.notes-section {
  textarea {
    height: 200px;
    resize: none;
    background-color: $grey-6;
    border-radius: 5px;
    border-color: $grey-5;
    font-family: "cairo-regular", sans-serif;

    &:focus {
      box-shadow: none;
      background-color: $grey-6;
      border-color: $grey-5;
    }
  }
}

.ng-select {
  .ng-select-container .ng-value-container .ng-placeholder {
    font-family: 'cairo-regular', sans-serif;
    font-size: 14px;
    color: #A0A3AE;
  }

  .ng-select-container {
    background-color: $grey-6;
    border-color: $grey-5;
    border-radius: 5px;
    min-height: 42px !important;
  }
  .pageSize .ng-select-container {
    background-color: $white;
  }

  &.invalid-field {
    border: 1px solid #D9727F;
    border-radius: 5px;
  }
}

.ng-select.ng-select-opened>.ng-select-container {
  background-color: $grey-6 !important;
  border-color: $grey-5 !important;
}

.ng-dropdown-panel.ng-select-bottom,
.ng-dropdown-panel.ng-select-top {
  background-color: $grey-6 !important;
  padding: 6px 0 6px 8px;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  box-shadow: none !important;
  border: none !important;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  border-width: 0 4px 6px;
  border-color: transparent transparent #111934;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: $grey-2;
  font-family: 'cairo-regular', sans-serif;
  font-size: 14px;
  background-color: $grey-6;

  &.ng-option-marked {
    background-color: $grey-5;
    color: $grey-2;
  }
}

.ng-value-label {
  font-family: 'cairo-regular', sans-serif;
  font-size: 14px;
  color: $grey-2;
}

.ng-dropdown-panel-items.scroll-host {
  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 5px;
  }

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 5px;
    left: 20px;
    margin-top: 10px
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0885C2;
    border-radius: 5px
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: $grey-2;
  background-color: $grey-5;
}

.ng-input input {
  font-family: 'cairo-regular', sans-serif !important;
  font-size: 14px !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-width: 6px 4px 2.5px;
  border-color: #111934 transparent transparent;
}

.ng-dropdown-panel.ng-select-bottom {
  border-top-color: $grey-5;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  padding: 0;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  font-family: 'cairo-regular', sans-serif;
  background-color: #fff;
  border-radius: 50%;
  color: #787F91;
  border: none;
  width: 13px;
  height: 13px;
  line-height: 12px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-right: none !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  position: relative;
  border-radius: 13px;
  background-color: $blue-primary !important;
  color: $white;
  font-family: 'cairo-regular', sans-serif;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.not-active {
  background-color: #A0A3AE !important;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  height: 25px;
  line-height: 24px;
  padding: 0px 8px 3px;
  color: $white;
  margin-left: 13px;
}

.ng-select.ng-select-opened>.ng-select-container,
.ng-select .ng-select-container {
  background-color: $grey-6 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-mdc-icon-button {
  vertical-align: bottom !important;

  .mat-icon {
    width: fit-content ;
    height: fit-content ;

    img {
      vertical-align: unset ;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: none !important;
  font-family: 'cairo-regular', sans-serif;
  font-size: 14px;
  color: $grey-2;
  padding: 8px 10px;
}

.invalid-field {
  .ng-select {
    border: 1px solid #D9727F;
    border-radius: 5px;
  }
}
