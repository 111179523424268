button.btn,
a.btn {
    border-color: transparent;
    border-radius: 5px;
    font-family: 'cairo-semibold', sans-serif;
    font-size: 14px;
    height: 42px;
    letter-spacing: -0.14px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active:focus {
        outline: 0;
        box-shadow: none;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.6;
    }

    @media screen and (max-width: $min-md - 1) {
        font-size: 13px;
    }

    img {
        width: 18px;
        height: 18px;
        margin-left: 10px;

        @media screen and (max-width: $min-md - 1) {
            width: 16px;
            height: 16px;
        }
    }

    // change hover for buttons only with class btn
    &.btn-auth {
        background-color: $success;
        color: #FFFFFF;
        width: 100%;
        border-radius: 5px;
    }

    &.btn-report {
        background-color: #CBCED5;
        color: #111934;
        width: 100%;
        border-radius: 10px;
    }

    &.btn-search {
        width: 140px;
        color: #FFFFFF;
        border-radius: 5px;
        background-color: #0885C2;
    }

    &.btn-primary {

        &:hover,
        &:not(:disabled):not(.disabled):active {
            background: $primary-hover;
            border: $primary-hover;
        }
    }

    &.btn-yellow {
        color: $primary !important;
        background-color: $yellow;

        &:hover,
        &:not(:disabled):not(.disabled):active {
            background: $yellow-hover;
            border: $yellow-hover;
            color: $primary !important;
        }
    }

    &.btn-success {

        &:hover,
        &:not(:disabled):not(.disabled):active {
            background: $success-hover;
            border: $success-hover;
        }
    }

    &.btn-grey-2 {
        background: $grey-hover;
        border: $grey-hover;

        &:hover,
        &:not(:disabled):not(.disabled):active {
            background: $grey-hover;
            border: $grey-hover;
        }
    }

    &.btn-error {

        &:hover,
        &:not(:disabled):not(.disabled):active {
            background: $error-hover;
            border: $error-hover;
        }
    }

    &.btn-green {
        background-color: $green-primary;
        color: $white;
        border: 1px solid $green-dark;

        &:hover {
            background: $green-dark;
        }
    }

    &.btn-delete {
        background-color: $red-primary;
        color: $white;
        border: 1px solid $red-dark;

        &:hover {
            background: $red-dark;
        }
    }

    &.btn-add {
        background-color: $blue-primary;
        color: $white;
        border: 1px solid $blue-dark;

        &:hover {
            background: $blue-dark;
        }
    }

    &.btn-confirm {
        background-color: $yellow-primary;
        color: $white;
        border: 1px solid $yellow-dark;

        &:hover {
            background: $yellow-dark;
        }
    }

    &.btn-cancel {
        background-color: $grey-5;
        color: $black-primary;
        border: 1px solid $grey-5;

        &:hover {
            background: $grey-5;
        }
    }

    &.btn-add-service {
        background-color: #FFFFFF;
        color: #787F91;
        border: 1px solid #CED1D6;
        border-radius: 5px;
        min-width: 150px;
    }

    &.btn-custom-delete {
        min-width: 42px;
        background-color: #FFFFFF;
        color: #787F91;
        border: 1px solid #DDE1E2;
        margin-left: 8px;
        
        img {
            position: unset;
            margin-left: 0 !important;
        }
    }
    &.btn-copy {
        background-color: #CFD1D6;
        span{
            color: #111934;
            font-size: 13px;
            margin-right: 5px;
        }
    }

}

// button status
.btn-checked {
    height: 32px;
    min-width: auto;
    border-radius: 5px;
    border: 0px;
    padding: 0px 15px;
    font-family: 'cairo-regular', sans-serif;
    pointer-events: none;
    font-size: 14px;
    letter-spacing: -0.14px;

    @media screen and (max-width: $min-md - 1) {
        font-size: 13px;
        letter-spacing: -0.13px;
    }
}

// button block in mobile view
.btn-full {
    width: fit-content;

    @media screen and (max-width: $min-md - 1) {
        width: 100%;
    }
}

.send-btn button {
    background-color: $secondary;
}

// change all btn colors
.btn-secondary,
.btn-grey-2 {
    color: $primary !important;

    &:hover {
        color: $primary !important;
    }
}

.btn-yellow,
.btn-orange,
.btn-lighter-green {
    color: $white !important;

    &:hover {
        color: $white !important;
    }
}

// buttons outline
.btn-outline-primary {
    border: 2px solid $primary !important;
    color: $primary !important;

    &:hover {
        background: $primary !important;
        border: 2px solid $primary !important;
        color: $white !important;

        img {
            filter: brightness(0) invert(1);
        }
    }
}

.btn-outline-light-grey-2 {
    border: 2px solid $light-grey-2 !important;
    color: $dark-grey !important;

    &:hover {
        background: $light-grey-2 !important;
        border: 2px solid $light-grey-2 !important;
        color: $dark-grey !important;
    }
}

// custom buttons
.add-new {
    background: #FAFAFB !important;
    border: 1px dashed rgba($color: $black, $alpha: .1) !important;
    box-shadow: none !important;
    display: flex;
    border-radius: 10px;
    padding-top: 12px;

    .btn-card {
        width: 100%;
        padding: 14px 4px 0px;

        &:focus {
            outline: none;
        }

        p {
            font-family: 'cairo-semibold', sans-serif;
            font-size: 14px;
            letter-spacing: -0.14px;
            height: 45px;
            line-height: 20px;
            display: flex;
            align-items: center;
            padding: 0px 2px;

            @media screen and (max-width: $min-md - 1) {
                font-size: 13px;
                letter-spacing: -0.13px;
                line-height: 16px;
                height: 40px;
            }
        }
    }
}

.btn-card {
    padding: 0px;
    background: transparent;
    border: 0px;

    img {
        width: 34px;
        height: 34px;

        @media screen and (max-width: $min-md - 1) {
            width: 26px;
            height: 26px;
        }
    }

    p {
        margin: 0px;
    }
}

.btn-upload,
.drag-btn {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;

    @media screen and (max-width: $min-md - 1) {
        margin-bottom: -10px;
    }

    button {
        display: flex;

        img {
            width: 20px;
        }
    }

    input[type=file] {
        font-size: initial;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        opacity: 0;
        height: 100%;
        cursor: pointer;


    }
}

// disable button choose file from input type file
::-webkit-file-upload-button {
    display: none;
}
